import Navbar from "./navbar";
import Footer from "./footer";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
// import BidsModal from "./modal/bidsModal";
// import BuyModal from "./modal/buyModal";

export default function Layout({ children }) {
  const { projectData } = useSelector((state) => state.counter);

  return (
    <div data-theme={projectData ? projectData.theme : "light"} className="">
      <Navbar />
      {/* <BidsModal />
      <BuyModal /> */}
      <div className="h-full flex flex-col justify-between">
      <main className="pt-4">{children}</main>
      <Footer />
      </div>
      <ToastContainer />
    </div>
  );
}
