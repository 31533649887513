import React, { useEffect, useState } from "react";
import Link from "next/link";
import { footerMenuList, socialIcons } from "../../data/footer_data";
import { useSelector } from "react-redux";
import { urlFor } from "../../sanity";
import { routeCheckSoloApp } from "../../utils/generalUtils";

const Footer = () => {
  const { projectData } = useSelector((state) => state.counter);
  const [socials, setSocials] = useState([
    {
      id: 0,
      href: "#",
      icon: "twitter",
    },
    {
      id: 1,
      href: "#",
      icon: "discord",
    },
    {
      id: 2,
      href: "#",
      icon: "globe",
    },
  ])


  useEffect(() => {
    if(projectData){
      socials[0].href = projectData.twitter || "#"
      socials[1].href = projectData.discord || "#"
      socials[2].href = projectData.website || "#"
    }
  }, [projectData])
  




  return (
    <div>
      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              {projectData ? <><Link href={routeCheckSoloApp(projectData.slug.current, '')}>
                <a className="mb-6 inline-block w-full">
                  <img
                    src={urlFor(projectData.logo).height(56)}
                    className="max-h-9 dark:hidden mb-6"
                    alt="SolClub | OneStop Token Utility"
                  />
                </a>
              </Link>

              {projectData.whitelogo && <Link href={routeCheckSoloApp(projectData.slug.current, '')}>
                <a className=" mb-6 inline-block">
                  <img
                    src={urlFor(projectData.whitelogo).height(56)}
                    className="hidden max-h-9 dark:block mb-6"
                    alt="SolClub | OneStop Token Utility"
                  />
                </a>
              </Link>}</> : <div className="animate-pulse h-14 w-40 bg-jacarta-200 rounded-lg">
                </div>}

              {/* <!-- Socials --> */}
              <div className="flex space-x-2">
                {socials.reverse().map((item) => {
                  const { id, href, icon } = item;
                  return (
                    <Link href={href} key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group cursor-pointer"
                      >
                        <svg className="icon text-jacarta-300 group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${icon}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>
{/* 
            {footerMenuList.map((single) => (
              <div
                className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                key={single.id}
              >
                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                  {single.title}
                </h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link href={href}>
                          <a className="hover:text-accent dark:hover:text-white">
                            {text}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))} */}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>© {new Date().getFullYear()} SolClub — Made with ❤️</span>
              <Link href="https://solclub.app">
                <a className="hover:text-accent dark:hover:text-white">
                  {" "}
                  RudeTech by RudeGolems
                </a>
              </Link>
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link href="/tarms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Terms and conditions
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/tarms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Privacy policy
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
