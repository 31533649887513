import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import Link from "next/link";
import { closeMblMenu } from "../../redux/counterSlice";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import ProfileDropdown from "../profileDropdown";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { FirebaseContext } from "../../contexts/FirebaseProvider";
import { routeCheckSoloApp } from "../../utils/generalUtils";


const MblNavbarLite = ({ theme }) => {
  const {publicKey} = useWallet()
  const {isAdmin} = useContext(FirebaseContext)
  const { mblMenu } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [profileShow, setProfileShow] = useState(false);
  const router = useRouter();
  const [navItemValue, setNavItemValue] = useState(1);
  const [navText, setnavText] = useState("");

  // useEffect(() => {
  //   console.log(isAdmin)
  //   if(!isAdmin && router.asPath.includes("admin/")){
  //     router.push("/")
  //   }
  // }, [router])
  



  const handleItemDropdown = (e) => {
    const target = e.target.closest("li");

    if (!target.classList.contains("show")) {
      target.classList.add("show");
    } else {
      target.classList.remove("show");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        dispatch(closeMblMenu());
      }
    });

    if (router.asPath === "/") {
      localStorage.setItem("navItemValue", 1);
    }
    if (router.asPath === "/home/home_2") {
      localStorage.setItem("navItemValue", 2);
    }
    if (router.asPath === "/home/home_3") {
      localStorage.setItem("navItemValue", 3);
    }
    if (router.asPath === "/home/home_4") {
      localStorage.setItem("navItemValue", 4);
    }
    if (router.asPath === "/home/home_5") {
      localStorage.setItem("navItemValue", 5);
    }
    if (router.asPath === "/home/home_6") {
      localStorage.setItem("navItemValue", 6);
    }
    if (router.asPath.includes("item")) {
      localStorage.setItem("navItemValue", 7);
    }
    if (router.asPath.includes("collection/avatar")) {
      localStorage.setItem("navItemValue", 9);
    }
    if (router.asPath === "/collection/explore_collection") {
      localStorage.setItem("navItemValue", 8);
    }
    if (router.asPath.includes("activity")) {
      localStorage.setItem("navItemValue", 10);
    }
    if (router.asPath.includes("ranking")) {
      localStorage.setItem("navItemValue", 11);
    }
    if (router.asPath.includes("user")) {
      localStorage.setItem("navItemValue", 12);
    }
    if (router.asPath.includes("profile")) {
      localStorage.setItem("navItemValue", 13);
    }
    if (router.asPath.includes("about")) {
      localStorage.setItem("navItemValue", 14);
    }
    if (router.asPath.includes("contact")) {
      localStorage.setItem("navItemValue", 15);
    }
    if (router.asPath.includes("wallet")) {
      localStorage.setItem("navItemValue", 16);
    }
    if (router.asPath.includes("404")) {
      localStorage.setItem("navItemValue", 18);
    }
    if (router.asPath.includes("tarms")) {
      localStorage.setItem("navItemValue", 19);
    }
    if (router.asPath.includes("help_center")) {
      localStorage.setItem("navItemValue", 20);
    }
    if (router.asPath.includes("partners")) {
      localStorage.setItem("navItemValue", 23);
    }
    if (router.asPath.includes("blog")) {
      localStorage.setItem("navItemValue", 24);
    }
    if (router.asPath.includes("single_post")) {
      localStorage.setItem("navItemValue", 25);
    }
    if (router.asPath.includes("newsletter")) {
      localStorage.setItem("navItemValue", 26);
    }
    if (router.asPath.includes("create")) {
      localStorage.setItem("navItemValue", 28);
    }
    if (router.asPath.includes("admin")) {
      localStorage.setItem("navItemValue", 100);
    }
    if (router.asPath.includes("admin/stats")) {
      localStorage.setItem("navItemValue", 102);
    }
    if (router.asPath.includes("admin/create")) {
      localStorage.setItem("navItemValue", 101);
    }

    const value = localStorage.getItem("navItemValue");
    setNavItemValue(+value);

    if (navItemValue > 0 && navItemValue <= 6) {
      setnavText("home");
    } else if (navItemValue > 6 && navItemValue <= 19) {
      setnavText("pages");
    } else if (navItemValue > 19 && navItemValue <= 26) {
      setnavText("resources");
    } else if (navItemValue === 28) {
      setnavText("create");
    } else if (navItemValue === 27) {
      setnavText("collection");
    } else if (navItemValue === 100) {
      setnavText("listings");
    } else if (navItemValue === 102) {
      setnavText("stats");
    } else if (navItemValue === 101) {
      setnavText("admin/create");
    }
  }, [dispatch, navItemValue, router]);
  const homenavData = [
    {
      id: 1,
      text: "home 1",
      url: "/",
    },
    {
      id: 2,
      text: "home 2",
      url: "/home/home_2",
    },
    {
      id: 3,
      text: "home 3",
      url: "/home/home_3",
    },
    {
      id: 4,
      text: "home 4",
      url: "/home/home_4",
    },
    {
      id: 5,
      text: "home 5",
      url: "/home/home_5",
    },
    {
      id: 6,
      text: "home 6",
      url: "/home/home_6",
    },
  ];

  const createTextData = [
    {
      id: 102,
      text: "Create Collections",
      href: routeCheckSoloApp(router.query.projectId, `admin/create/collection`),
    },
    {
      id: 103,
      text: "Create Raffles",
      href: routeCheckSoloApp(router.query.projectId, `admin/create/raffle`),
    },
    {
      id: 104,
      text: "Create Auctions",
      href: routeCheckSoloApp(router.query.projectId, `admin/create/auction`),
    }
  ];

  const resourcesData = [
    {
      id: 20,
      text: "Help Center",
      href: "/help_center",
    },
    {
      id: 21,
      text: "Platform Status",
      href: "/platform_status",
    },
    {
      id: 23,
      text: "Partners",
      href: "/partners",
    },
    {
      id: 24,
      text: "Blog",
      href: "/blog",
    },
    {
      id: 25,
      text: "Single Post",
      href: "/single_post/post_1",
    },
    {
      id: 26,
      text: "Newsletter",
      href: "/newsletter",
    },
  ];

  return (
    <div
      className={
        mblMenu
          ? "js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent nav-menu--is-open"
          : "js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent"
      }
    >


<nav className="navbar w-full">
        {isAdmin && <ul className="flex flex-col lg:flex-row">
        <li className="js-nav-dropdown group relative">
            <Link href={routeCheckSoloApp(router.query.projectId, 'admin/stats')}>
            <a
              className={"dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"}
              // onClick={(e) => handleItemDropdown(e)}
            >
              <span className={navText === "stats" ? "text-accent" : ""}>
                Stats
              </span>

              <i className="lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                </svg>
              </i>
            </a>
            </Link>
          </li>
          <li className="js-nav-dropdown group relative">
            <Link href={routeCheckSoloApp(router.query.projectId, 'admin')}>
            <a
              className={"dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"}
              onClick={(e) => handleItemDropdown(e)}
            >
              <span className={navText === "listings" ? "text-accent" : ""}>
                Listings
              </span>

              <i className="lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                </svg>
              </i>
            </a>
            </Link>
          </li>
          <li className="js-nav-dropdown group relative">
            <button
              className={
                router.asPath === "/home/home_3"
                  ? "dropdown-toggle font-display  hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:text-white text-jacarta-700 dark:text-white lg:px-5 w-full"
                  : "dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span className={navText === "admin/create" ? "text-accent" : ""}>
                Create
              </span>

              <i className="lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                </svg>
              </i>
            </button>
            <ul className="dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative">
              {createTextData.map(({ id, text, href }) => {
                return (
                  <li key={id}>
                    <Link href={href}>
                      <a
                        className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors"
                        onClick={() => {
                          setNavItemValue(id);
                          localStorage.setItem("navItemValue", id);
                        }}
                      >
                        <span
                          className={
                            navItemValue === id
                              ? "font-display text-accent text-sm"
                              : "font-display text-jacarta-700 text-sm dark:text-white"
                          }
                        >
                          {text}
                        </span>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
          </ul>}
        </nav>
      {/* <!-- Actions --> */}
      <div className="ml-8 hidden lg:flex xl:ml-12">



        {/* <!-- Profile --> */}
        <WalletMultiButton />
        {publicKey &&  <div className="js-nav-dropdown group-dropdown relative">
            <button
              className="dropdown-toggle border border-accent hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-12 w-12 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              onMouseEnter={() => setProfileShow(true)}
              onMouseLeave={() => setProfileShow(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
              </svg>
            </button>

            <ProfileDropdown profileShow={profileShow} setProfileShow={setProfileShow}/>
        </div>}

        
      </div>
    </div>
  );
};

export default MblNavbarLite;
